import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "submit-values" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]
const _hoisted_5 = ["value"]
const _hoisted_6 = ["value"]
const _hoisted_7 = ["value"]
const _hoisted_8 = ["value"]
const _hoisted_9 = ["value"]
const _hoisted_10 = ["value"]
const _hoisted_11 = ["value"]
const _hoisted_12 = ["value"]
const _hoisted_13 = ["value"]
const _hoisted_14 = ["value"]
const _hoisted_15 = ["value"]
const _hoisted_16 = ["value"]
const _hoisted_17 = ["value"]
const _hoisted_18 = ["value"]
const _hoisted_19 = ["value"]
const _hoisted_20 = ["value"]
const _hoisted_21 = ["value"]
const _hoisted_22 = ["value"]
const _hoisted_23 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("input", {
      type: "hidden",
      name: "paymentType",
      value: _ctx.payment.type
    }, null, 8, _hoisted_2),
    _createElementVNode("input", {
      type: "hidden",
      name: "interval",
      value: _ctx.payment.interval
    }, null, 8, _hoisted_3),
    _createElementVNode("input", {
      type: "hidden",
      name: "amount",
      value: _ctx.payment.amount
    }, null, 8, _hoisted_4),
    _createElementVNode("input", {
      type: "hidden",
      name: "iban",
      value: _ctx.bankdata.iban
    }, null, 8, _hoisted_5),
    _createElementVNode("input", {
      type: "hidden",
      name: "bic",
      value: _ctx.bankdata.bic
    }, null, 8, _hoisted_6),
    _createElementVNode("input", {
      type: "hidden",
      name: "addressType",
      value: _ctx.addressType
    }, null, 8, _hoisted_7),
    (_ctx.sendNameAndEmail)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("input", {
            type: "hidden",
            name: "salutation",
            value: _ctx.address.salutation
          }, null, 8, _hoisted_8),
          _createElementVNode("input", {
            type: "hidden",
            name: "title",
            value: _ctx.address.title
          }, null, 8, _hoisted_9),
          _createElementVNode("input", {
            type: "hidden",
            name: "firstName",
            value: _ctx.address.firstName
          }, null, 8, _hoisted_10),
          _createElementVNode("input", {
            type: "hidden",
            name: "lastName",
            value: _ctx.address.lastName
          }, null, 8, _hoisted_11),
          _createElementVNode("input", {
            type: "hidden",
            name: "email",
            value: _ctx.address.email
          }, null, 8, _hoisted_12)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.sendPostalAddress)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("input", {
            type: "hidden",
            name: "street",
            value: _ctx.street
          }, null, 8, _hoisted_13),
          _createElementVNode("input", {
            type: "hidden",
            name: "postcode",
            value: _ctx.address.postcode
          }, null, 8, _hoisted_14),
          _createElementVNode("input", {
            type: "hidden",
            name: "city",
            value: _ctx.address.city
          }, null, 8, _hoisted_15),
          _createElementVNode("input", {
            type: "hidden",
            name: "country",
            value: _ctx.address.country
          }, null, 8, _hoisted_16)
        ], 64))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      type: "hidden",
      name: "companyName",
      value: _ctx.address.companyName
    }, null, 8, _hoisted_17),
    _createElementVNode("input", {
      type: "hidden",
      name: "info",
      value: _ctx.newsletter
    }, null, 8, _hoisted_18),
    _createElementVNode("input", {
      type: "hidden",
      name: "donationReceipt",
      value: _ctx.receipt
    }, null, 8, _hoisted_19),
    _createElementVNode("input", {
      type: "hidden",
      name: "impCount",
      value: _ctx.trackingData.impressionCount
    }, null, 8, _hoisted_20),
    _createElementVNode("input", {
      type: "hidden",
      name: "bImpCount",
      value: _ctx.trackingData.bannerImpressionCount
    }, null, 8, _hoisted_21),
    _createElementVNode("input", {
      type: "hidden",
      name: "piwik_campaign",
      value: _ctx.campaignValues.campaign
    }, null, 8, _hoisted_22),
    _createElementVNode("input", {
      type: "hidden",
      name: "piwik_kwd",
      value: _ctx.campaignValues.keyword
    }, null, 8, _hoisted_23)
  ]))
}