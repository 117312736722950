<template>
	<svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M20.9359 18.3427L14.1179 11.1997L20.9359 4.05772C21.8509 3.09872 21.8149 1.57973 20.8569
		0.664725C19.8969 -0.251275 18.3779 -0.215275 17.4639 0.742725L10.7999 7.72373L4.1359 0.742725C3.2219
		-0.215275 1.7019 -0.252275 0.7429 0.664725C-0.2161 1.57973 -0.2511 3.09872 0.6639 4.05772L7.4819 11.1997L0.6639
		18.3427C-0.2511 19.3017 -0.2161 20.8217 0.7429 21.7367C1.7019 22.6517 3.2209 22.6157 4.1359 21.6567L10.7999
		14.6757L17.4629 21.6567C17.9349 22.1517 18.5669 22.4007 19.1999 22.4007C19.7949 22.4007 20.3919 22.1797 20.8569
		21.7367C21.8149 20.8217 21.8509 19.3017 20.9359 18.3427Z" fill="#000000" />
	</svg>
</template>
