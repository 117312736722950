<template>
	<div class="wikimedia-logo-graphic">
		<!-- eslint-disable max-len -->
		<svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
			<g filter="url(#filter0_i_1201_240)">
				<path fill-rule="evenodd" clip-rule="evenodd"
					d="M19.5635 29.0678C24.9393 28.3082 29.0748 23.6884 29.0748 18.1022C29.0748 14.7082 27.5481 11.6716 25.1448 9.63965L19.5635 15.2217V29.0678ZM16.4371 29.0678V15.2217L10.8562 9.63965C8.45247 11.6713 6.92578 14.7082 6.92578 18.1022C6.92578 23.6884 11.0615 28.3082 16.4371 29.0678Z"
					fill="#FEFEFE"/>
				<path fill-rule="evenodd" clip-rule="evenodd"
					d="M27.6241 8.47659C30.1949 11.0479 31.6101 14.4665 31.6101 18.1025C31.6101 21.7388 30.1949 25.1565 27.6241 27.728C25.0531 30.2993 21.6352 31.7154 18.0004 31.7154C14.3648 31.7154 10.9464 30.2993 8.37587 27.728C5.80509 25.1567 4.38918 21.7381 4.38918 18.1028C4.38918 14.4665 5.80509 11.0481 8.37587 8.47683C8.59567 8.25732 8.82273 8.0452 9.05667 7.84083L5.94729 4.73096C5.71678 4.93902 5.49171 5.15304 5.27233 5.3728C3.62863 7.0123 2.31892 8.95537 1.4159 11.0941C0.476514 13.3153 0 15.6732 0 18.1025C0 20.5316 0.476514 22.8896 1.4159 25.1107C2.31877 27.2496 3.6285 29.1926 5.27233 30.832C6.91139 32.4761 8.85425 33.7861 10.9929 34.6892C13.2131 35.6286 15.5711 36.1053 18.0004 36.1053C20.429 36.1053 22.7862 35.6286 25.0071 34.6892C27.1458 33.7861 29.0886 32.4761 30.7277 30.832C32.3715 29.1926 33.6812 27.2496 34.5841 25.1107C35.5235 22.8893 36 20.5316 36 18.1025C36 15.6732 35.5235 13.3155 34.5841 11.0941C33.6811 8.95537 32.3714 7.0123 30.7277 5.3728C30.5083 5.15304 30.2832 4.93902 30.0527 4.73096L26.9433 7.84083C27.1773 8.0452 27.4043 8.25707 27.6241 8.47659Z"
					fill="#FEFEFE"/>
				<path fill-rule="evenodd" clip-rule="evenodd"
					d="M17.9997 0C21.0981 0 23.6095 2.51186 23.6095 5.61053C23.6095 8.70945 21.0981 11.2218 17.9997 11.2218C14.9015 11.2218 12.3896 8.70945 12.3896 5.61053C12.3896 2.51186 14.9015 0 17.9997 0Z"
					fill="#FEFEFE"/>
				<path fill-rule="evenodd" clip-rule="evenodd"
					d="M19.5635 29.0678C24.9393 28.3082 29.0748 23.6884 29.0748 18.1022C29.0748 14.7082 27.5481 11.6716 25.1448 9.63965L19.5635 15.2217V29.0678ZM16.4371 29.0678V15.2217L10.8562 9.63965C8.45247 11.6713 6.92578 14.7082 6.92578 18.1022C6.92578 23.6884 11.0615 28.3082 16.4371 29.0678Z"
					fill="black"/>
				<path fill-rule="evenodd" clip-rule="evenodd"
					d="M27.6241 8.47659C30.1949 11.0479 31.6101 14.4665 31.6101 18.1025C31.6101 21.7388 30.1949 25.1565 27.6241 27.728C25.0531 30.2993 21.6352 31.7154 18.0004 31.7154C14.3648 31.7154 10.9464 30.2993 8.37587 27.728C5.80509 25.1567 4.38918 21.7381 4.38918 18.1028C4.38918 14.4665 5.80509 11.0481 8.37587 8.47683C8.59567 8.25732 8.82273 8.0452 9.05667 7.84083L5.94729 4.73096C5.71678 4.93902 5.49171 5.15304 5.27233 5.3728C3.62863 7.0123 2.31892 8.95537 1.4159 11.0941C0.476514 13.3153 0 15.6732 0 18.1025C0 20.5316 0.476514 22.8896 1.4159 25.1107C2.31877 27.2496 3.6285 29.1926 5.27233 30.832C6.91139 32.4761 8.85425 33.7861 10.9929 34.6892C13.2131 35.6286 15.5711 36.1053 18.0004 36.1053C20.429 36.1053 22.7862 35.6286 25.0071 34.6892C27.1458 33.7861 29.0886 32.4761 30.7277 30.832C32.3715 29.1926 33.6812 27.2496 34.5841 25.1107C35.5235 22.8893 36 20.5316 36 18.1025C36 15.6732 35.5235 13.3155 34.5841 11.0941C33.6811 8.95537 32.3714 7.0123 30.7277 5.3728C30.5083 5.15304 30.2832 4.93902 30.0527 4.73096L26.9433 7.84083C27.1773 8.0452 27.4043 8.25707 27.6241 8.47659Z"
					fill="black"/>
				<path fill-rule="evenodd" clip-rule="evenodd"
					d="M18.236 0C21.3345 0 23.8458 2.51186 23.8458 5.61053C23.8458 8.70945 21.3345 11.2218 18.236 11.2218C15.1378 11.2218 12.626 8.70945 12.626 5.61053C12.626 2.51186 15.1378 0 18.236 0Z"
					fill="black"/>
			</g>
			<defs>
				<filter id="filter0_i_1201_240" x="0" y="0" width="36" height="40.1055" filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix"/>
					<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"/>
					<feOffset dy="4"/>
					<feGaussianBlur stdDeviation="2"/>
					<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
					<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.02 0"/>
					<feBlend mode="normal" in2="shape" result="effect1_innerShadow_1201_240"/>
				</filter>
			</defs>
		</svg>
	</div>
</template>

<style lang="scss">
@import "../../scss/variables";
@import "~bulma/sass/utilities/mixins";

.wikimedia-logo-graphic {
	width: 36px;
	height: 37px;
}
</style>
